import {Injectable, signal} from '@angular/core';
import {Application} from './application.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsStoreService {
  private applications = signal<Application[]>([]);

  setStores(apps: Application[]): void {
    this.applications.set(apps);
  }

  getApps(): Application[] {
    return this.applications();
  }

  getAppById(id: number): Application | undefined {
    return this.getApps().find((app) => app.id === id);
  }
}
